@import './tailwind.css';

@keyframes highlight {
    0% {
      border-color: transparent;
    }
    50% {
      border-color: red;
    }
    100% {
      border-color: transparent;
    }
  }
  
  .highlight {
    animation: highlight 1s ease-in-out;
    border: 2px solid red;
  }
  